export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,8,9,12,11,17];

export const dictionary = {
		"/(auth)/callbacks/facebook": [~18,[2]],
		"/(auth)/callbacks/google": [~19,[2]],
		"/(auth)/create-profile": [~20,[3]],
		"/(protected)/dashboard": [~25,[7,8]],
		"/(protected)/dashboard/account": [26,[7,8]],
		"/(protected)/dashboard/account/billing": [27,[7,8]],
		"/(protected)/dashboard/account/profile": [28,[7,8]],
		"/(protected)/dashboard/account/profile/notifications": [29,[7,8]],
		"/(protected)/dashboard/account/profile/security": [30,[7,8]],
		"/(protected)/dashboard/account/subscriptions": [~31,[7,8]],
		"/(protected)/dashboard/account/team": [~32,[7,8]],
		"/(protected)/dashboard/analytics": [~33,[7,8]],
		"/(protected)/dashboard/leads": [~34,[7,8]],
		"/(protected)/dashboard/marketing": [35,[7,8,9]],
		"/(protected)/dashboard/marketing/[generateCampaignStep]": [~36,[7,8,9]],
		"/(protected)/dashboard/websites": [37,[7,8]],
		"/(protected)/dashboard/websites/domains": [~38,[7,8]],
		"/(protected)/dashboard/websites/seo": [39,[7,8]],
		"/(auth)/email-confirmation": [~21,[4]],
		"/flow": [~54],
		"/flow/01/1": [~55,[12]],
		"/flow/01/2": [~56,[12]],
		"/(auth)/login": [~22],
		"/(protected)/lp/edit": [~40,[7,10]],
		"/(protected)/lp/edit/preview": [41,[7,10]],
		"/maintenance": [~57],
		"/(protected)/marketing/create": [42,[7,11]],
		"/(protected)/marketing/create/budget": [43,[7,11]],
		"/(protected)/marketing/create/discount": [44,[7,11]],
		"/(protected)/marketing/create/email": [45,[7,11]],
		"/(protected)/marketing/create/location-2": [47,[7,11]],
		"/(protected)/marketing/create/location-2/cities": [~48,[7,11]],
		"/(protected)/marketing/create/location-2/states": [~49,[7,11]],
		"/(protected)/marketing/create/location": [46,[7,11]],
		"/(protected)/marketing/create/phone": [50,[7,11]],
		"/(protected)/marketing/create/preview": [51,[7,11]],
		"/(protected)/marketing/create/service": [52,[7,11]],
		"/onboard": [~58],
		"/onboard/preview": [~59,[13]],
		"/onboard/preview/[lpKey]": [~60,[13]],
		"/(protected)/payment/inprogress": [53,[7]],
		"/(auth)/recover-password": [~23,[5]],
		"/(auth)/reset-password": [~24,[6]],
		"/website-builder/(inprogress)/inprogress": [61,[15]],
		"/website-builder/(register)/register": [~62,[16]],
		"/website-source": [63,[17]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';